import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28" {...props}>
    <path
      fill="#2970FF"
      fillRule="evenodd"
      d="M9.555 2.476c0 1.367-1.089 2.475-2.432 2.475S4.69 3.843 4.69 2.476C4.69 1.108 5.78 0 7.123 0s2.432 1.108 2.432 2.476M3.433 6.829.875 28h8.303l.713-14.939 2.851 9.22h2.977l4.068-9.22L18.36 28h8.764l-.839-21.17h-7.338l-4.193 8.75-3.9-8.75zm19.373-1.878c1.343 0 2.432-1.108 2.432-2.475C25.238 1.108 24.15 0 22.806 0s-2.432 1.108-2.432 2.476 1.089 2.475 2.432 2.475"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLogo;
