import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.475 18.333h5c4.167 0 5.833-1.666 5.833-5.833v-5c0-4.167-1.666-5.833-5.833-5.833h-5c-4.167 0-5.833 1.666-5.833 5.833v5c0 4.167 1.666 5.833 5.833 5.833"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m1.642 10.583 5-.016c.625 0 1.325.475 1.558 1.058l.95 2.4c.217.542.558.542.775 0l1.908-4.842c.184-.466.525-.483.759-.041l.866 1.641c.259.492.925.892 1.475.892h3.384"
    />
  </svg>
);
export default SvgHealth;
