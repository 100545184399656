import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d="M7.083 15.833h-.416c-3.334 0-5-.833-5-5V6.667q0-5 5-5h6.666q5 0 5 5v4.166q0 5-5 5h-.416a.85.85 0 0 0-.667.334L11 17.833c-.55.734-1.45.734-2 0l-1.25-1.666c-.133-.184-.442-.334-.667-.334"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.33 9.167h.008M9.996 9.167h.008M6.662 9.167h.008"
    />
  </svg>
);
export default SvgMessage;
