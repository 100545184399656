import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessageNotif = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18.333 8.333v2.5q0 5-5 5h-.416a.85.85 0 0 0-.667.334L11 17.833c-.55.734-1.45.734-2 0l-1.25-1.666c-.133-.184-.442-.334-.667-.334h-.416c-3.334 0-5-.833-5-5V6.667q0-5 5-5h5"
    />
    <path fill="#F04438" d="M16.667 6.667a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.33 9.167h.008M9.996 9.167h.008M6.662 9.167h.008"
    />
  </svg>
);
export default SvgMessageNotif;
