import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.333 8.333V12.5c0 4.167-1.666 5.833-5.833 5.833h-5c-4.167 0-5.833-1.666-5.833-5.833v-5c0-4.167 1.666-5.833 5.833-5.833h4.167"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.333 8.333H15c-2.5 0-3.333-.833-3.333-3.333V1.667z"
    />
  </svg>
);
export default SvgDocument;
