import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendarCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666M8.242 4.858v1.667M11.758 4.858v1.667M6.275 8.45h7.45"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d="M11.25 14.725h-2.5c-2.083 0-3.333-1.5-3.333-3.333v-2.5c0-1.834 1.25-3.334 3.333-3.334h2.5c2.083 0 3.333 1.5 3.333 3.334v2.5c0 1.833-1.25 3.333-3.333 3.333"
    />
  </svg>
);
export default SvgCalendarCircle;
