import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18 7.91h-.13c-1.89-.06-3.3-1.52-3.3-3.32 0-1.84 1.5-3.33 3.33-3.33s3.33 1.5 3.33 3.33a3.327 3.327 0 0 1-3.22 3.33c0-.01 0-.01-.01-.01m-.1-5.16c-1.01 0-1.83.82-1.83 1.83 0 .99.77 1.79 1.76 1.83.01-.01.09-.01.18 0a1.832 1.832 0 0 0-.11-3.66M18.01 15.28c-.39 0-.78-.03-1.17-.1a.75.75 0 0 1-.61-.87.75.75 0 0 1 .87-.61c1.23.21 2.53-.02 3.4-.6.47-.31.72-.7.72-1.09s-.26-.77-.72-1.08c-.87-.58-2.19-.81-3.43-.59-.41.08-.8-.2-.87-.61s.2-.8.61-.87c1.63-.29 3.32.02 4.52.82.88.59 1.39 1.43 1.39 2.33 0 .89-.5 1.74-1.39 2.34-.91.6-2.09.93-3.32.93M5.97 7.91h-.02a3.324 3.324 0 0 1-3.22-3.32c0-1.84 1.5-3.34 3.33-3.34s3.33 1.5 3.33 3.33c0 1.81-1.41 3.27-3.21 3.33l-.21-.75.07.75zm.1-1.5c.06 0 .11 0 .17.01.89-.04 1.67-.84 1.67-1.83a1.83 1.83 0 1 0-1.93 1.83c.01-.01.05-.01.09-.01M5.96 15.28c-1.23 0-2.41-.33-3.32-.93-.88-.59-1.39-1.44-1.39-2.34 0-.89.51-1.74 1.39-2.33 1.2-.8 2.89-1.11 4.52-.82.41.07.68.46.61.87s-.46.69-.87.61c-1.24-.22-2.55.01-3.43.59-.47.31-.72.69-.72 1.08s.26.78.72 1.09c.87.58 2.17.81 3.4.6.41-.07.8.21.87.61.07.41-.2.8-.61.87-.39.07-.78.1-1.17.1M12 15.38h-.13c-1.89-.06-3.3-1.52-3.3-3.32 0-1.84 1.5-3.33 3.33-3.33s3.33 1.5 3.33 3.33a3.327 3.327 0 0 1-3.22 3.33c0-.01 0-.01-.01-.01m-.1-5.16c-1.01 0-1.83.82-1.83 1.83 0 .99.77 1.79 1.76 1.83.01-.01.09-.01.18 0 .97-.05 1.72-.85 1.73-1.83 0-1-.82-1.83-1.84-1.83M12 22.76c-1.2 0-2.4-.31-3.33-.94-.88-.59-1.39-1.43-1.39-2.33 0-.89.5-1.75 1.39-2.34 1.87-1.24 4.8-1.24 6.66 0 .88.59 1.39 1.43 1.39 2.33 0 .89-.5 1.75-1.39 2.34-.93.62-2.13.94-3.33.94m-2.5-4.35c-.47.31-.72.7-.72 1.09s.26.77.72 1.08c1.35.91 3.64.91 4.99 0 .47-.31.72-.7.72-1.09s-.26-.77-.72-1.08c-1.34-.91-3.63-.9-4.99 0"
    />
  </svg>
);
export default SvgPeople;
